<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
          	<h1 class="text-typo font-weight-bolder mb-0">Historial de ventas por Fecha</h1>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( modalidades  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="getIniciarConsulta( )"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
          </v-card-title>

          <v-card-text class="black--text mt-6">
            <v-row>

              <v-col cols="12" sm="4" lg="3" xl="2">
              	<label class="grey--text">Fecha inicial</label>
	              <v-text-field
	                filled
	                dense
	                single-line
	                hide-details
	                label="Fecha"
	                type="date"
	                v-model="fecha_inicio"
	              ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
              	<label class="grey--text">Fecha final</label>
	              <v-text-field
	                filled
	                dense
	                single-line
	                hide-details
	                label="Fecha"
	                type="date"
	                v-model="fecha_final"
	              ></v-text-field>
              </v-col>


              <v-col cols="12" sm="4" lg="3" xl="2">
                <label class="grey--text">Selecciona un ciclo</label>
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclosAll"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  single-line
                >
                </v-autocomplete>
              </v-col>

            </v-row>

            <v-row class="mt-0 grey--text">
            	<v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Activos</label>
                <v-select
                  :items="['Activo', 'Todo']"
                  v-model="activos"
                  label="Activos"
                  filled
                  dense
                  single-line
                ></v-select>
            	</v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Ventas</label>
                <v-select
                  :items="['Alumnos', 'Dinero']"
                  v-model="ventas"
                  label="Venta"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0">
                <label>Enfoque</label>
                <v-select
                  :items="['Vendedora', 'Plantel']"
                  v-model="enfoque"
                  label="Enfoque"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="3" lg="2" class="mt-0 pb-0" v-if="enfoque == 'Plantel'">
                <label class="grey--text">TIPO de consulta</label>
                <v-select
                  :items="['NI', 'RI', 'TODO']"
                  v-model="tipoConsulta"
                  label="RI O NI"
                  filled
                  dense
                  single-line
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" lg="4" class="mt-0 pt-0" v-if="enfoque == 'Vendedora'">
                <label class="grey--text">Vendedora</label>
                <v-autocomplete
                  v-if="enfoque == 'Vendedora'"
                  filled
                  clearable
                  v-model="vendedora"
                  :items="vendedorasAll"
                  label="nombre vendedora"
                  dense
                  item-text="vendedora"
                  item-value="id_usuario"
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="4" class="mt-0 pt-0" v-if="enfoque == 'Plantel'">
                <label class="grey--text">Plantel</label>
                <v-autocomplete
                  v-if="enfoque == 'Plantel'"
                  filled
                  clearable
                  v-model="plantel"
                  :items="vendedorasAll"
                  label="nombre plantel"
                  dense
                  item-text="plantel"
                  item-value="plantel"
                  single-line
                  hide-details
                >
                </v-autocomplete>
              </v-col>
            </v-row>

            <v-row v-if="headers.length">
            	<v-col cols="12">
            		<v-card class="shadowCard">
            			<v-card-text v-if="verGraficaInbi">
                    <vue-apex-charts v-if="verGraficaInbi" :options="chartOptions" :series="seriesInbi" type="bar"></vue-apex-charts>
            			</v-card-text>
            		  <v-card-text>
            		    <!-- Tabla de ingresos y egresos de fast -->
					          <v-data-table
					            :headers="headers"
					            :items="filterVendedorasInbiAll"
					            class="elevation-0"
					            :search="search"
					            dense
					            mobile-breakpoint="200"
					          >
					            
					          </v-data-table>
            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>


            <v-row v-if="headers.length">
            	<v-col cols="12">
            		<v-card class="shadowCard">
            			<v-card-text v-if="verGraficaFast">
                    <vue-apex-charts v-if="verGraficaFast" :options="chartOptions" :series="seriesFast" type="bar"></vue-apex-charts>
            			</v-card-text>
            		  <v-card-text>
            		    <!-- Tabla de ingresos y egresos de fast -->
					          <v-data-table
					            :headers="headers"
					            :items="filterVendedorasFastAll"
					            class="elevation-0"
					            :search="search"
					            dense
					            mobile-breakpoint="200"
					          >
					          </v-data-table>
            		  </v-card-text>
            		</v-card>
            	</v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  import VueApexCharts from 'vue-apexcharts'

  const formatter = new Intl.NumberFormat('en-US', {
	  style: 'currency',
	  currency: 'USD',
	});

  export default {

    components:{
      Alerta,
      carga,
      VueApexCharts,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      anios:[],
      ciclos:[],
      ciclo: null,
      anio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 4),
      cicloFinal: null,
      fechasUnicas:[],

      headers:[],
      vendedorasInbiAll:[],
      vendedorasFastAll:[],
      search:'',

      verGrafica: false,
      verGraficaFast: false,
      seriesFast: [],

      verGraficaInbi: false,
      seriesInbi: [],

      fecha_inicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha_final:  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      activos:'Activo',
      enfoque:'Vendedora',
      ventas: 'Alumnos',
      tipoConsulta:'NI',

      cicloInf:null,

      ciclosAll:[],

      chart: null,

      chartOptions: {
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          toolbar: {
            show: true
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 15,
            dataLabels: {
              total: {
                enabled: true,
                style: {
                  fontSize: '13px',
                  fontWeight: 900
                }
              },
            }
          },
        },
        xaxis: {
          categories: [],
        },
        grid: {
          row: {
            colors: ['#fff', '#f2f2f2']
          }
        },
        yaxis: {
          title: {
            text: 'Plantel / Vendedora',
          },
        },
        fill: {
          opacity: 1
        }
      },

      chartSeries: [],
      vendedora: null,
      vendedorasAll : [],
      plantel: null,
      fechas:[],
    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),

      filterCiclos( ){
      	if( this.anio ){
      		return this.ciclos.filter( el => { return el.anio == this.anio })
      	}else{
      		return []
      	}
      },


      filterVendedorasInbiAll ( ) {
        let data = this.vendedorasInbiAll
      	
        if( this.activos == 'Activo' ){
      		// rediseñar tabla
      		data = data.filter( el => { return el.activo_sn == 1 }) 
      	}
      	
        if( this.vendedora ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.id_usuario == this.vendedora}) 
        }

        if( this.plantel ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.plantel == this.plantel}) 
        }

        return data
      },

			filterVendedorasFastAll ( ) {
        let data = this.vendedorasFastAll
        
        if( this.activos == 'Activo' ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 }) 
        }
        
        if( this.vendedora ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.id_usuario == this.vendedora}) 
        }

        if( this.plantel ){
          // rediseñar tabla
          data = data.filter( el => { return el.activo_sn == 1 && el.plantel == this.plantel}) 
        }

        return data
			},

    },

    async created () {
      await this.initialize()
      await this.getCiclos( )
    },

    watch:{
    	activos( value ){
        if( this.enfoque == 'Vendedora'){
    		  this.updateTabla()
        }else{
          this.updateTablaPlantel()
        }
    	},

      vendedora ( value ){
        if( this.enfoque == 'Vendedora')
          this.updateTabla()
        else
          this.updateTablaPlantel()
      },

      plantel ( value ){
        if( this.enfoque == 'Vendedora')
          this.updateTabla()
        else
          this.updateTablaPlantel()
      }
    },

    methods: {

      getCiclos () {
        this.cargar    = true
        this.ciclosAll    = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclosAll.push(response.data[i])
              }
            }
          }
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize () {
      	this.verGrafica     = false
      	this.verGraficaFast = false
        this.verGraficaInbi = false
      },

      getIniciarConsulta ( ){
        this.chartOptions.xaxis.categories     = []
        this.seriesInbi                        = []
        this.seriesFast                        = []
        this.chartSeries                       = []
        this.verGrafica                        = false
        this.verGraficaFast                    = false
        this.verGraficaInbi                    = false

        if(this.enfoque == 'Vendedora'){
          this.getHistorialVentas()
        }else{
          this.getHistorialVentasPlantel()
        }
      },

      getHistorialVentas(){
      	this.cargar        = true
        this.fechasUnicas = []

        let payload = {
        	ventas      : this.ventas,
        	fecha_inicio: this.fecha_inicio,
        	fecha_final : this.fecha_final
        }

        this.$http.post('reporte.historial.ventas.fecha', payload ).then(response=>{yij

        	this.fechasUnicas      = response.data.fechasUnicas
          this.fechas            = response.data.fechas
        	this.headers           = response.data.headers
        	this.vendedorasInbiAll = response.data.vendedorasInbiAll
          this.vendedorasFastAll = response.data.vendedorasFastAll
        	this.vendedorasAll     = response.data.vendedorasAll.filter( el => { return el.activo_sn == 1 })

          this.updateTabla()

          this.cargar         = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getHistorialVentasPlantel(){
        this.cargar        = true
        this.fechasUnicas  = []

        let payload = {
        	ventas      : this.ventas,
        	fecha_inicio: this.fecha_inicio,
        	fecha_final : this.fecha_final,
          cicloInf    : this.cicloInf
        }

        let path = ''

        if( this.tipoConsulta == 'NI' ){ path = 'reporte.historial.ventas.plantel.fecha' }
        if( this.tipoConsulta == 'RI' ){ path = 'reporte.historial.ventas.plantel.ri.fecha' }
        if( this.tipoConsulta == 'TODO' ){ path = '' }

        this.$http.post(path, payload ).then(response=>{

          this.fechasUnicas      = response.data.fechasUnicas
          this.fechas            = response.data.fechas
          this.headers           = response.data.headers
          this.vendedorasInbiAll = response.data.vendedorasInbiAll
          this.vendedorasFastAll = response.data.vendedorasFastAll
          this.vendedorasAll     = response.data.vendedorasAll.filter( el => { return el.activo_sn == 1 })

          this.updateTablaPlantel()

          this.cargar         = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      updateTabla( ){
        this.verGrafica     = false
      	this.verGraficaFast = false
        this.verGraficaInbi = false

        this.seriesInbi      = []
        this.seriesFast      = []

        // this.chartOptions.xaxis.categories =  this.fechasUnicas.map(( registro ) => { return registro.fecha_formateada })
        this.chartOptions.xaxis.categories = []

        for( const j in this.fechasUnicas ){
        	console.log( this.fechasUnicas[j].fecha_formateada )
          this.chartOptions.xaxis.categories.push(this.fechasUnicas[j].fecha_formateada )
    		}

      	console.log( this.chartOptions.xaxis.categories )

        for( const i in this.filterVendedorasInbiAll ){

      		const { vendedora } = this.filterVendedorasInbiAll[i]

      		let data = []
      		for( const j in this.fechasUnicas ){

            let totales = 0
            
            if( this.ventas == 'Alumnos')
      			  totales = this.fechasUnicas[j].datos_inbi.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).length
      			else
              totales = this.fechasUnicas[j].datos_inbi.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            data.push(totales)
      		}

          if( vendedora != 'TOTAL' ){
        		this.seriesInbi.push({
        			name: this.filterVendedorasInbiAll[i].vendedora,
        			data
      			})
          }
      	}

      	for( const i in this.filterVendedorasFastAll ){

      		const { vendedora } = this.filterVendedorasFastAll[i]

      		let data = []
      		
          for( const j in this.fechasUnicas ){
      			
            let totales = 0
            
            if( this.ventas == 'Alumnos')
              totales = this.fechasUnicas[j].datos_fast.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).length
            else
              totales = this.fechasUnicas[j].datos_fast.filter( el => { return el.vendedora == vendedora && el.vendedora != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)
            
            data.push(totales)
      		}

          if( vendedora != 'TOTAL' ){
        		this.seriesFast.push({
        			name: this.filterVendedorasFastAll[i].vendedora,
        			data
      			})
          }

      	}


        this.verGrafica     = true
        this.verGraficaFast = true
        this.verGraficaInbi = true
        this.cargar         = false
      },

      updateTablaPlantel( ){
        this.verGrafica     = false
        this.verGraficaFast = false
        this.verGraficaInbi = false

        this.chartOptions.xaxis.categories     = []
        this.seriesInbi      = []
        this.seriesFast      = []
        this.chartSeries     = []

        for( const j in this.fechasUnicas ){
          this.chartOptions.xaxis.categories.push(this.fechasUnicas[j].fecha_formateada )
    		}
        

        for( const i in this.filterVendedorasInbiAll ){

          const { plantel } = this.filterVendedorasInbiAll[i]

          let data = []
          for( const j in this.fechasUnicas ){

            let totales = 0

            if( this.ventas == 'Alumnos')
              totales = this.fechasUnicas[j].datos_inbi.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL'}).length
            else
              totales = this.fechasUnicas[j].datos_inbi.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL'}).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            data.push(totales)
          }

          if( plantel != 'TOTAL' ){
            this.seriesInbi.push({
              name: this.filterVendedorasInbiAll[i].plantel,
              data
            })
          }
        }

        for( const i in this.filterVendedorasFastAll ){

          const { plantel } = this.filterVendedorasFastAll[i]

          let data = []
          for( const j in this.fechasUnicas ){

            console.log( this.fechasUnicas[j].datos_fast )
            
            let totales = 0

            if( this.ventas == 'Alumnos')
              totales = this.fechasUnicas[j].datos_fast.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL' }).length
            else
              totales = this.fechasUnicas[j].datos_fast.filter( el => { return el.plantel == plantel && el.plantel != 'TOTAL' }).map(item => item.pagado).reduce((prev, curr) => prev + curr, 0).toFixed(2)

            data.push(totales)
          }

          if( plantel != 'TOTAL' ){
            this.seriesFast.push({
              name: this.filterVendedorasFastAll[i].plantel,
              data
            })
          }


        }

        this.chartOptions.xaxis.categories =  this.fechasUnicas.map(( registro ) => { return registro.fecha_creacion })

        this.verGrafica     = true
        this.verGraficaFast = true
        this.verGraficaInbi = true
        this.cargar         = false
      },

      exportar(){
        this.exportarData()
      },
    },
  }
</script>
<style scoped>

  .avatares{
    background-image: linear-gradient(310deg,#17ad37,#98ec2d) !important;
  }


  .anteriores{
    background: rgb(135,45,176);
    background: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
  }

  .gradient-text {
    background-image: linear-gradient(149deg, rgba(135,45,176,0.9612219887955182) 0%, rgba(235,44,244,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .gradient-text-percent{
    background-image: linear-gradient(149deg, rgb(8,110,0) 0%, rgba(44,244,57,0.9836309523809523) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .text-typo{
  	color: #344767!important;
  }

  .apexcharts-canvas {
    max-width: 600px;
    margin: 0 auto;
  }

</style>
